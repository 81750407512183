//

import { instance } from '@/utils/http.js'
import { instances as rpcinstance } from '@/utils/httpAaser.js'

// 首页备考模式策略
export const getHomeRefrence = function (params) {
  return instance({
    url: '/api/v1/ddup/getHomePointWeak',
    method: 'get',
    params
  })
}

// 首页学习模式策略
export const getHomeStudy = function (params) {
  return instance({
    url: '/api/v1/ddup/getNewHomeKnowledgeWeak',
    method: 'get',
    params
  })
}

// 首页协助导出学生学情
export const getTeacherReport = function (params) {
  return instance({
    url: '/api/v1/user/teacher_report',
    method: 'post',
    // headers: {
    //   // application/msword 表示要处理为word格式
    //   // application/vnd.ms-excel 表示要处理为excel格式
    //   'Content-Type': 'application/json; charset=UTF-8',
    // },
    // responseType: 'blob',
    params
  })
}

// 首页导出各科目弱点题目
export const getExplainReport = function (params) {
  return instance({
    url: '/api/v1/user/check_explain_report',
    method: 'post',
    // headers: {
    //   // application/msword 表示要处理为word格式
    //   // application/vnd.ms-excel 表示要处理为excel格式
    //   'Content-Type': 'application/json; charset=UTF-8',
    // },
    // responseType: 'blob',
    params
  })
}

export const closeIntroVideo = function () {
  return instance({
    url: '/api/v1/user/close_video',
    method: 'post',
  })
}

export const messageStatus = function (params) {
  return rpcinstance({
    url: '/api/rpc/v1/mq/status',
    method: 'get',
    params
  })
}